'use client'

import { useRouter, useSearchParams } from 'next/navigation'
import { Alert, AlertActions, AlertDescription, AlertTitle } from '~/components/ui/alert'
import { Button } from '~/components/ui/button'

export function ErrorModal() {
  const router = useRouter()
  const searchParams = useSearchParams()
  const error = searchParams.get('error')
  const isOpen = error !== null && error !== ''

  const setIsOpen = () => {
    router.replace('/auth/signin')
  }

  return (
    <Alert open={isOpen} onClose={setIsOpen}>
      <AlertTitle>로그인 중 에러가 발생했습니다.</AlertTitle>
      <AlertDescription>
        새로고침하여 다시 시도해주세요. 문제가 지속되면 <code>support@typeit.ai</code>로 문의해주세요.
      </AlertDescription>
      <AlertActions>
        <Button onClick={() => setIsOpen()}>닫기</Button>
      </AlertActions>
    </Alert>
  )
}
